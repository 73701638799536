<template>
  <product-by-brand-report />
</template>

<script>
import ProductByBrandReport from '@/components/pages/product/ProductByBrandReport'

export default {
  components: {
    ProductByBrandReport
  }
}
</script>
