<template>
  <FormSelect
    label="Store"
    :values="values"
    :options="options"
    :rules="[VALIDATION.requiredArray]"
    :loading="loading"
    :disable="loading || disabled"
    :multiple="multiple"
    @updateStore="onUpdateStore"
    @click="onUpdateStore"
  />
</template>

<script>
import { ref, watch, onBeforeMount } from 'vue'
import { FormSelect } from '@/components/inputs'
import { VALIDATION, Toast, fetchData } from '@/tools'

export default {
  name: 'SelectUserStores',
  inheritAttrs: true,
  components: {
    FormSelect
  },
  props: {
    values: {
      type: [String, Number, Array],
      default: null
    },
    multiple: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    area: {
      type: String,
      default: null
    }
  },
  setup(props, { emit }) {
    const { data, error, loading, get } = fetchData()
    const { showToast } = Toast()
    const options = ref([])

    const onUpdateStore = data => {
      emit('updateStore', data)
    }

    const getStores = () => {
      if (props.area) {
        get(`v1/user/me?area_id=${props.area}`)
      } else {
        get('v1/user/me')
      }
    }

    watch(
      () => props.area,
      () => {
        getStores()
      }
    )

    onBeforeMount(() => {
      getStores()
      watch([data, error, loading], () => {
        if (!loading.value) {
          if (error?.value) {
            showToast('There was a problem fetching stores.', 'danger')
          } else if (data?.value) {
            options.value = data?.value?.store_access?.map(item => ({
              label: item?.name,
              value: item?.id
            }))
          }
        }
      })
    })

    return { options, loading, VALIDATION, onUpdateStore }
  }
}
</script>
