<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader
        breadcrumb="Product"
        title="By Brand Report"
        subTitle="How Is My Business Doing?"
      />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        label="Export"
        size="lg"
        :disabled="!hasData"
        outline
        @click="exportTable"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_export')" width="30" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <div class="col q-col-gutter-md">
      <div>
        <FilterCard>
          <q-form ref="filterForm" @submit="onFilter" @reset="onReset">
            <div class="row items-start q-col-gutter-md">
              <div class="col-xs-12 col-sm-6 col-md-2">
                <FormDate
                  label="Month"
                  mask="####/##"
                  defaultView="YearMonth"
                  v-model="date"
                  :value="date"
                  :rules="[VALIDATION.required]"
                  @input="onSelectDate"
                  tabindex="1"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-2">
                <SelectUserStores
                  v-model="store"
                  :values="store"
                  @updateStore="$event => (store = $event)"
                  tabindex="2"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-2">
                <FormSelect
                  label="Top X"
                  v-model="top"
                  :values="top"
                  :options="topOptions"
                  :rules="[VALIDATION.required]"
                  tabindex="3"
                />
              </div>

              <div class="col-xs-12 col-sm-6 col-md-2">
                <FormSelect
                  label="Sort By"
                  v-model="sortBy"
                  :values="sortBy"
                  :options="sortByOptions"
                  :rules="[VALIDATION.required]"
                  tabindex="4"
                />
              </div>

              <div
                class="flex justify-end gap-4 col-xs-12 col-sm-12 col-md-4 text-right"
              >
                <Button
                  variant="primary"
                  label="Reset"
                  icon="refresh"
                  type="reset"
                  outline
                  tabindex="5"
                />
                <Button
                  variant="primary"
                  label="Filter"
                  icon="o_filter_alt"
                  :loading="!isFiltered && loadingSales"
                  :disabled="!isFiltered && loadingSales"
                  type="submit"
                  tabindex="6"
                />
              </div>
            </div>
          </q-form>
        </FilterCard>
      </div>

      <Loader :loading="!isFiltered && loadingSales" />

      <NoDataCard v-if="noData" />

      <div class="col q-col-gutter-md" v-if="hasData">
        <div>
          <q-card class="q-pa-lg">
            <q-card-section>
              <HorizontalBarChart
                id="ByBrandReport"
                type="ByBrandReport"
                axis="xy"
                :xLabel="
                  sortBy === 'trx_count'
                    ? 'Transaction Count'
                    : sortBy === 'basket_size'
                    ? 'Basket Size'
                    : 'Net Sales'
                "
                yLabel="Brand Name"
                :xData="
                  sortBy === 'trx_count'
                    ? 'transCount'
                    : sortBy === 'basket_size'
                    ? 'basketSize'
                    : 'netSales'
                "
                yData="brand"
                :data="salesTableData"
              />
            </q-card-section>
          </q-card>
        </div>
        <div>
          <Table
            :rows="salesTableData"
            :columns="salesTableHeader"
            :loading="loadingTable"
            :limit="limitPage"
            :page="pageNo"
            :total="totalCount"
          />
        </div>
      </div>
    </div>
  </PageContent>

  <Modal
    :show="showModal"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <h5 class="q-mb-md">Products By Brand Report</h5>
    <p>Generate products by brand by month and store</p>

    <q-separator class="q-my-md" />

    <h6 class="q-mb-md">Filters</h6>
    <p><strong>Month:</strong> required field</p>
    <p><strong>Store:</strong> required field</p>
    <p><strong>Top:</strong> required field</p>
    <p><strong>Sort By:</strong> required field</p>
  </Modal>
</template>

<script>
import { ref, computed, watch, onBeforeMount } from 'vue'
import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import { FilterCard, NoDataCard } from '@/components/cards'
import { FormDate, FormSelect } from '@/components/inputs'
import Button from '@/components/Button'
import Table from '@/components/Table'
import Modal from '@/components/Modal'
import { HorizontalBarChart } from '@/components/charts'
import Loader from '@/components/Loader'
import SelectUserStores from '@/components/customs/SelectUserStores'

import {
  DATE,
  FORMAT,
  VALIDATION,
  EXPORT,
  Toast,
  fetchData,
  getIconUrl
} from '@/tools'

export default {
  name: 'ProductByBrandReport',
  components: {
    PageHeader,
    PageContent,
    FilterCard,
    NoDataCard,
    FormDate,
    FormSelect,
    Button,
    Table,
    Modal,
    HorizontalBarChart,
    Loader,
    SelectUserStores
  },
  setup() {
    const { showToast } = Toast()
    const {
      data: dataSales,
      error: errorSales,
      loading: loadingSales,
      post: postSales
    } = fetchData()

    const showModal = ref(false)
    const loadingTable = ref(false)
    const isFiltered = ref(false)
    const limitPage = ref(10)
    const pageNo = ref(1)
    const totalCount = ref(0)

    const filterForm = ref(null)
    const date = ref(DATE.toFriendlyYearMonth(new Date()))
    const store = ref([])
    const top = ref(null)
    const topOptions = ref([
      {
        label: '10',
        value: 10
      },
      {
        label: '20',
        value: 20
      },
      {
        label: '50',
        value: 50
      },
      {
        label: 'all',
        value: 'all'
      }
    ])
    const sortBy = ref(null)
    const sortByOptions = ref([
      {
        label: 'Sales',
        value: 'net_sales'
      },
      {
        label: 'Transaction Count',
        value: 'trx_count'
      },
      {
        label: 'Basket Size',
        value: 'basket_size'
      }
    ])
    const salesTableData = ref(null)

    const salesTableHeader = ref([
      {
        name: 'brand',
        label: 'Brand Name',
        field: 'brand',
        align: 'left',
        sortable: true
      },
      {
        name: 'netSales',
        label: 'Net Sales',
        field: row => FORMAT.toCurrency(row.netSales),
        align: 'center',
        sortable: true
      },
      {
        name: 'transCount',
        label: 'Transaction Count',
        field: row => FORMAT.toNumber(row.transCount),
        align: 'center',
        sortable: true
      },
      {
        name: 'basketSize',
        label: 'Basket Size',
        field: row => FORMAT.toNumber(row.basketSize),
        align: 'center',
        sortable: true
      },
      {
        name: 'basketPenetration',
        label: 'Basket Penetration',
        field: 'basketPenetration',
        align: 'center',
        sortable: true
      }
    ])

    const hasData = computed(
      () => salesTableData.value && salesTableData.value.length > 0
    )

    const noData = computed(
      () => salesTableData.value && salesTableData.value.length === 0
    )

    const sortedTable = computed(() => {
      let sortName

      switch (sortBy.value) {
        case 'net_sales': {
          sortName = 'netSales'
          break
        }
        case 'trx_count': {
          sortName = 'transCount'
          break
        }
        case 'basket_size': {
          sortName = 'basketSize'
          break
        }
        default: {
          sortName = 'brand'
        }
      }

      return sortName
    })

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const onFilter = () => {
      if (!isFiltered.value) {
        limitPage.value = 10
        pageNo.value = 1
        totalCount.value = 0
      }

      filterForm.value.validate().then(success => {
        if (success) {
          const payload = {
            pagination: {
              page: pageNo.value,
              per_page: limitPage.value
            },
            filters: {
              month_year: DATE.toFriendlyFirstDayMonth(date.value),
              stores: store.value,
              sort_by: sortBy.value,
              limit: top.value
            }
          }

          postSales('v1/product/by-brand-report', payload)
        } else {
          showToast('Please fill all the required fields', 'info')
        }
      })
    }

    const onReset = () => {
      filterForm.value.resetValidation()
      date.value = DATE.toFriendlyYearMonth(new Date())
      store.value = []
      top.value = null
      sortBy.value = null
      salesTableData.value = null
      limitPage.value = 10
      pageNo.value = 1
      totalCount.value = 0
    }

    const onSelectDate = data => {
      date.value = data
    }

    const exportTable = () => {
      EXPORT.exportToExcel(
        salesTableHeader.value,
        salesTableData.value,
        'product_by_brand_report'
      )
    }

    const onRequest = props => {
      const { page, rowsPerPage } = props.pagination
      isFiltered.value = true
      pageNo.value = page
      limitPage.value = rowsPerPage

      onFilter()
    }

    watch([sortBy], () => {
      if (salesTableData?.value?.length > 0)
        salesTableData.value = salesTableData.value.sort((a, b) =>
          a[sortedTable.value] > b[sortedTable.value] ? -1 : 1
        )
    })

    onBeforeMount(() => {
      watch([dataSales, errorSales, loadingSales], () => {
        if (loadingSales.value) {
          if (isFiltered.value) {
            loadingTable.value = true
          } else {
            salesTableData.value = null
          }
        } else {
          if (errorSales?.value) {
            showToast(
              errorSales?.value?.message ??
                'There was a problem fetching sales.',
              'danger'
            )
          } else if (dataSales?.value) {
            totalCount.value = dataSales?.value?.total

            salesTableData.value = dataSales?.value?.map(item => ({
              brand: item.brand_name,
              netSales: item.net_sales,
              transCount: item.trx_count,
              basketSize: item.basket_size,
              basketPenetration: FORMAT.toNumber(item.basket_penetration)
            }))

            salesTableData.value = salesTableData.value.sort((a, b) =>
              a[sortedTable.value] > b[sortedTable.value] ? -1 : 1
            )
          }

          isFiltered.value = false
          loadingTable.value = false
        }
      })
    })

    return {
      showModal,
      loadingTable,
      isFiltered,
      limitPage,
      pageNo,
      totalCount,
      filterForm,
      date,
      store,
      top,
      topOptions,
      sortBy,
      sortByOptions,
      salesTableHeader,
      salesTableData,
      loadingSales,
      FORMAT,
      VALIDATION,
      getIconUrl,
      hasData,
      noData,
      sortedTable,
      onToggleModal,
      onFilter,
      onReset,
      onSelectDate,
      exportTable,
      onRequest
    }
  }
}
</script>
